import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { MutationFunctionOptions, ExecutionResult } from 'react-apollo';
import { IWithCampaign } from './withCampaigns';
import { PaginationProps } from '../../utils/pagination';
import { GET_CAMPAIGNS } from '../../pages/Campaigns/withCampaigns';

const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($input: CampaignWhereUniqueInput!) {
    deleteCampaign(where: $input) {
      campaignId
    }
  }
`;

export interface ICampaignProps extends IWithCampaign {
  deleteCampaign: (
    options?: MutationFunctionOptions<any, Record<string, any>>
  ) => Promise<ExecutionResult<any>>;
}

function withDeleteCampaign(
  WrappedComponent: React.FC<ICampaignProps & PaginationProps>
) {
  return (props: PropsWithChildren<IWithCampaign & PaginationProps>) => {
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
      refetchQueries: [{
        query: GET_CAMPAIGNS,
        variables: {
          input: {
            orderBy: props.orderBy,
            order: props.order,
            offset: props.rowsPerPage * props.page,
            first: props.rowsPerPage
          },
          searchByName: props.searchByNamePhrase
        }
      }
    ],
      awaitRefetchQueries: true
    });
    return <WrappedComponent {...props} deleteCampaign={deleteCampaign} />;
  };
}

export default withDeleteCampaign;
