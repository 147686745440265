import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import App from './App';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import { ApolloClient, IdGetterObj } from 'apollo-boost';
import { logout } from './session/logout';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
  credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    logout();
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (
      object: IdGetterObj & { campaignId?: string; }
    ) => {
      if(object.__typename === 'Campaign') {
        return object.campaignId;
      }
      return defaultDataIdFromObject(object);
    }
  }),
  link: ApolloLink.from([errorLink, httpLink])
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
