import { EditCampaign } from './EditCampaign';
import withCampaign from './withCampaignData';
import withUpdateCampaign from './withUpdateCampaign';
import withPagination from '../../utils/pagination';

const initialOrder = 'name';
const initialSortingOrder = 'desc';

//@ts-ignore
export default withPagination(
    initialOrder,
    initialSortingOrder
    //@ts-ignore
)(withCampaign(withUpdateCampaign(EditCampaign)));
