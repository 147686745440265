import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { ICampaign } from './types';
import { ApolloQueryResult } from 'apollo-boost';
import { PaginationProps } from '../../utils/pagination';

export const GET_CAMPAIGNS = gql`
  query Campaigns($input: CampaignsWhereInput!, $searchByName: String) {
    campaigns(where: $input, searchByNamePhrase: $searchByName) {
      adPartner {
        id
        name
      }
      campaignId
      isActive
      priority
      maxViewsPerViewer
      name
      startDate
      stopDate
      cpm
      targetViewsNumber
      lastModification
      duration
      isAssetBlocked
    }
    countCampaigns(searchByNamePhrase: $searchByName)
  }
`;

interface ICampaignsData {
  campaigns?: ICampaign[];
  countCampaigns: number;
  loading?: boolean;
}

export interface IWithCampaign extends ICampaignsData {
  refetch: (
    variables?: {} | undefined
  ) => Promise<ApolloQueryResult<ICampaignsData>>;
}

function withCampaigns(
  WrappedComponent: React.FC<IWithCampaign & PaginationProps>
) {
  return (props: PropsWithChildren<IWithCampaign & PaginationProps>) => {
    const { loading, error, data, refetch } = useQuery<ICampaignsData, {}>(
      GET_CAMPAIGNS,
      { variables: {
        input: {
          orderBy: props.orderBy,
          order: props.order,
          offset: props.rowsPerPage * props.page,
          first: props.rowsPerPage
        },
        searchByName: props.searchByNamePhrase
      }}
    );

    if (error) return <p>Wystąpił Błąd</p>;

    const { campaigns, countCampaigns } = data || {};
    return (
      <WrappedComponent {...props} 
        campaigns={campaigns}
        refetch={refetch}
        totalRows={countCampaigns}
        loading={loading} />
    );
  };
}

export default withCampaigns;
